/* typography */
@font-face {
	font-family:"Chiswick";
	src:url('./assets/fonts/ChiswickSans-Regular-Web.woff2') format("woff2"),
			url('./assets/fonts/ChiswickSans-Regular-Web.woff') format("woff"),
      url('./assets/fonts/ChiswickSans-Regular-Web.eot') format("eot");
	font-style: normal;
	font-weight: 500;
}

@font-face {
	font-family:"Robinson";
	src:url('./assets/fonts/Robinson-Bold-Web.woff2') format("woff2"),
			url('./assets/fonts/Robinson-Bold-Web.woff') format("woff"),
      url('./assets/fonts/Robinson-Bold-Web.eot') format("eot");
	font-style: normal;
	font-weight: 700;
}

/* Global style */
body {
  margin: 0;
  font-family: "Chiswick";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: 0.5px;
  font-weight: 400;
  cursor: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2220%22%20height%3D%2220%22%20viewBox%3D%220%200%2020%2020%22%3E%0A%20%20%3Cdefs%3E%0A%20%20%20%20%3Cstyle%3E%0A%20%20%20%20%20%20.a%20%7B%0A%20%20%20%20%20%20%20%20fill%3A%20blue%3B%0A%20%20%20%20%20%20%7D%0A%20%20%20%20%3C%2Fstyle%3E%0A%20%20%3C%2Fdefs%3E%0A%20%20%3Ctitle%3Ecursor-blue%3C%2Ftitle%3E%0A%20%20%3Ccircle%20class%3D%22a%22%20cx%3D%2210%22%20cy%3D%2210%22%20r%3D%2210%22%2F%3E%0A%3C%2Fsvg%3E%0A'), pointer;
}

a:hover {
  color: #0000ff;
  cursor: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2220%22%20height%3D%2220%22%20viewBox%3D%220%200%2020%2020%22%3E%0A%20%20%3Cdefs%3E%0A%20%20%20%20%3Cstyle%3E%0A%20%20%20%20%20%20.a%20%7B%0A%20%20%20%20%20%20%20%20fill%3A%20red%3B%0A%20%20%20%20%20%20%7D%0A%20%20%20%20%3C%2Fstyle%3E%0A%20%20%3C%2Fdefs%3E%0A%20%20%3Ctitle%3Ecursor-red%3C%2Ftitle%3E%0A%20%20%3Ccircle%20class%3D%22a%22%20cx%3D%2210%22%20cy%3D%2210%22%20r%3D%2210%22%2F%3E%0A%3C%2Fsvg%3E%0A'), pointer;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

*{box-sizing: border-box;}
